export const filenames = [
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979206/mom/_20190815-20190815_111721_appjn5.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979227/mom/_20200828-2020-08-28_122549_Restored_tywayt.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979235/mom/_Les100a_zewij5.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979373/mom/_IMG_60111_1_vdhnkr.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979382/mom/_Les130a_qwl0fu.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979418/mom/_Les109a_fdglzt.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979437/mom/_2020-08-31_173711_aqix0v.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979441/mom/_Les117a_yd20c9.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979449/mom/2020-08-27_185329_zkronb.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979462/mom/DSC_0241_deambb.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979467/mom/DSC_0246_jqppyp.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979530/mom/DSC_0039_dazdsw.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979559/mom/DSC_0046_xav7za.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979564/mom/DSC_0050_c1smkq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979570/mom/DSC_0052_iw1nwe.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979604/mom/DSC00493_pv7iu1.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979609/mom/Les1b_bmzvsq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979611/mom/Les2a_wl6g7x.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979612/mom/Les3a_cqzlsf.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979624/mom/Les4b_tnrg6b.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979625/mom/Les5b_s435ix.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979627/mom/Les6b_qogd8o.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979629/mom/Les7a_b6bccq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979630/mom/Les8a_besmb6.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979635/mom/Les9a_qykijc.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979637/mom/Les10a_wshm73.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979638/mom/Les11a_owj5r2.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979639/mom/Les12a_nmor2d.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979641/mom/Les13b_z2v4eq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979642/mom/Les14a_rbusgu.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979644/mom/Les15b_jfdq94.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979645/mom/Les16a_udwy9w.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979647/mom/Les17a_a1dkml.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979649/mom/Les18a_xt5nw3.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979650/mom/Les19a_gvybiq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979659/mom/Les20a_ij4voz.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979660/mom/Les21a_gxxyvl.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979663/mom/Les22a_efxieg.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979665/mom/Les23a_fxlyhq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979666/mom/Les24a_k4ktdy.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979669/mom/Les25a_r00vmt.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979672/mom/Les26a_n5srmx.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979675/mom/Les27a_kmxbnp.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979680/mom/Les28a_eec8sq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979683/mom/Les29a_xk9tcs.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979686/mom/Les30a_tam0jg.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979690/mom/Les31a_jqapda.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979693/mom/Les32a_ivlbze.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979696/mom/Les33a_ksyzlc.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979700/mom/Les34a_g8swzc.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979708/mom/Les35a_hkaurf.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979711/mom/Les36a_obk8ns.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979716/mom/Les37a_xrxoyt.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979719/mom/Les38a_dzfj40.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979723/mom/Les39a_g2smrf.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979727/mom/Les40a_s13gch.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979762/mom/Les41a_ekw4xn.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979764/mom/Les42a_tf4mbl.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979767/mom/Les43a_sm6q5p.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979768/mom/Les44a_aoqj0h.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979770/mom/Les45a_fvusvr.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979773/mom/Les46a_t9utou.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979775/mom/Les47a_rgteah.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979778/mom/Les50a_zjoktq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979782/mom/Les54a_r5d0jk.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979785/mom/Les55a_fruk48.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979788/mom/Les57a_e6ddx6.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979791/mom/Les58a_f28ogs.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979793/mom/Les71a_zzsnmp.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979796/mom/Les72a_kyo2je.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979798/mom/Les73a_fdddhn.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979801/mom/Les74a_y4d8iw.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979803/mom/Les76a_wj8ytr.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979806/mom/Les79a_hz0712.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979808/mom/Les80a_gfmolz.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979811/mom/Les81a_wskfxj.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979814/mom/Les82a_gyo0ed.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979816/mom/Les83a_mxrkwt.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979852/mom/Les84a_xg42ln.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979859/mom/Les85a_o0u2qb.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979861/mom/Les86a_jj20ax.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979868/mom/Les87a_imol4u.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979868/mom/Les89a_o16mre.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979871/mom/Les88a_mbubsd.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979890/mom/Les92a_sr7g5s.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979892/mom/Les103a_kiwvbl.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979895/mom/Les104a_spd5z4.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979898/mom/Les107a_pjfbue.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979901/mom/Les112a_pvsmsl.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979905/mom/Les114a_vszoay.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979906/mom/Les115a_Skipped_zaw7tw.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979909/mom/Les118a_rsd5yv.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601979923/mom/Les116a_dtqls3.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980824/mom/Les118a_ojefcq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980827/mom/Les119a_rko16v.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980830/mom/Les120a_bre7ud.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980832/mom/Les121a_w0gflo.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980834/mom/Les122a_o3idwi.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980837/mom/Les123a_y7wwsg.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980839/mom/Les124a_eviudl.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980842/mom/Les125a_p9sygl.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980844/mom/Les126a_lsuxgr.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980863/mom/Les127a_iohtm6.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980866/mom/Les129a_h4mvg5.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980869/mom/Les131a_bfudmb.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980872/mom/Les132a_xauz5v.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980875/mom/Les133a_reub4p.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980878/mom/Les134a_ugistg.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980880/mom/Les135a_wphowf.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980883/mom/Les136a_v5fsr7.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980886/mom/Les137a_iouiar.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980890/mom/Les138a_gpbjji.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980892/mom/Les139a_fmtcxb.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980894/mom/Les140a_yoq4b8.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980895/mom/Les141a_k4v8ol.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980898/mom/Les142a_dkgegq.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980901/mom/Les143a_gmtun9.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980903/mom/Les145a_arqcid.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980906/mom/Les146a_rlcjp8.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980908/mom/Les148a_s0p279.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980911/mom/Les149a_oqq7oc.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980914/mom/Les150a_fuhygv.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980917/mom/Les151a_don0l2.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980940/mom/Les153a_gfro1m.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980942/mom/Les154a_r65g14.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980945/mom/Les155a_eoeef1.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980947/mom/Les157a_yrid9p.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980949/mom/Les158a_jyj6aj.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980954/mom/Les159a_r89wpu.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980957/mom/Les160a_ta0uw2.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980959/mom/Les161a_mbvqm7.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980962/mom/Les162a_fn0yqd.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980965/mom/Les163a_oaegeo.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980968/mom/Les164a_nedmg1.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980971/mom/Les165a_vh2kb0.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980974/mom/Les166a_dvvu6y.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980978/mom/Les167a_gngmaw.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980980/mom/Les168a_subphr.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980984/mom/Les169a_gaqvw5.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980987/mom/Les170a_iayoyv.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980989/mom/Les171a_ikh4s9.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980992/mom/Les172a_rurfi3.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601980995/mom/Les173a_uuyrgz.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981003/mom/Les174a_ks6vok.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981006/mom/Les175a_nqpkit.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981009/mom/Les176a_inpa5j.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981011/mom/Les177a_wofyqa.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981014/mom/Les182a_kkkfgm.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981018/mom/Les185a_hurlay.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981045/mom/DSC_0060_symyda.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981060/mom/DSC_0077_u02ovf.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981067/mom/DSC_0084_c4uvgz.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981073/mom/DSC_0086_ptpyg9.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981081/mom/DSC_0113_wllc2z.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981089/mom/DSC_0262_zgfire.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981100/mom/DSC_0270_oaiyny.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981109/mom/DSC_0275_esnsp7.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981114/mom/DSC_0274_ytrukc.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981121/mom/DSC_0278_lx7no9.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981130/mom/DSC_0279_igbu8c.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981138/mom/DSC_0307_sfdgx1.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981145/mom/DSC_0308_drisdh.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981154/mom/DSC_0314_zzoqm8.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981166/mom/DSC_0352_bzgxe6.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981174/mom/DSC_0371_rgy6bj.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981182/mom/DSC_0373_uo0b0h.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981189/mom/DSC_0375_u2hdng.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981202/mom/DSC_0498_hjhw5n.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981215/mom/DSC_0506_knjomi.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981228/mom/DSC_0538_fcsi8b.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981242/mom/DSC_0540_wj5tro.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981249/mom/DSC_0547_gp4bhc.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981268/mom/DSC_0549_tyxiv0.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981291/mom/DSC_0984_zrjyqa.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981305/mom/DSC_0988_skjvkk.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981308/mom/DSC_0985_quvizw.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981309/mom/DSC_0986_zualai.jpg",
    "https://res.cloudinary.com/dlyfm9kis/image/upload/v1601981310/mom/DSC_0987_twmkie.jpg",
];

const column1 = [];
const column2 = [];
const column3 = [];

// Split the pictures into 3 columns
filenames.forEach(function(value, index){
if((index % 3) === 0) {
    column1.push(value);
} else if((index % 3) === 1) {
    column2.push(value);
} else {
    column3.push(value);
}
})

export const galleryColumnOne = column1;
export const galleryColumnTwo = column2;
export const galleryColumnThree = column3;